import React from "react"
import Styled from "styled-components"

const Ruled = Styled.h1`
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    &::before {
        margin-left: -51%;
        text-align: right;
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '';
        background-color: ${({ theme }) => theme.primaryDark};;
    }
    &::after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '';
        background-color: ${({ theme }) => theme.primaryDark};;
        margin-left: 2%;
    }
`
const RuledBreak = ({ children }) => (
  <Ruled className="quicksand-light">{children}</Ruled>
)

export default RuledBreak
